import React, { useEffect, useState } from 'react';
import { Link, graphql, navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PanelFaq from '../components/CommonPanelFaq';
import Modal from '../components/Modal';

import iconArrowRight from '../assets/images/vectors/icons/ic_arrow-right.svg';
import imageCustomers from '../assets/images/photos/pricing/customers.png';
import imageConfetiLeft from '../assets/images/vectors/pricing/confeti_left.svg';
import imageConfetiRight from '../assets/images/vectors/pricing/confeti_right.svg';

import '../assets/styles/pages/pricing-page.scss';

const PricingPage = ({ data }) => {
  const [isOpenModal, setStateModal] = useState(false);
  const showModal = () => {
    setStateModal(true);
  };
  useEffect(() => {
    navigate('/');
  }, []);
  const hideModal = () => {
    setStateModal(false);
  };
  const prismicData = data.prismic.allPricing_pages.edges[0].node;

  return (
    <Layout>
      <SEO title="Pricing" />
      <div className="pricing-page">
        <FirstSection showModal={showModal} prismicData={prismicData} />
        <SecondSection prismicData={prismicData} />
        <ThirdSection prismicData={prismicData} />
      </div>
      {isOpenModal && (
        <Modal handleClose={hideModal}>
          <div className="common-modal__content--pricing">
            <div className="c-h5--bold">Become a member</div>
            <p>
              If you want to become a member, please reach out to the person who gave you this link.
            </p>
            <button className="c-btn" onClick={hideModal} type="button">
              Okay
            </button>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

const FirstSection = ({
  showModal,
  prismicData: { firstSectionTitle, firstSectionCaption, firstSectionCards },
}) => (
  <section className="first-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12 col-xl-9 mx-auto">
          <div className="c-h1">{RichText.asText(firstSectionTitle)}</div>
          <div className="c-caption-4">{RichText.asText(firstSectionCaption)}</div>
          <div className="first-section__wrapper">
            {firstSectionCards.map((item, index) => (
              <div className="first-section__card" key={index}>
                <img
                  className="first-section__card__image"
                  src={item.image.url}
                  alt={RichText.asText(item.title)}
                />
                <div className="first-section__card__description">
                  <div className="c-h5--bold">{RichText.asText(item.title)}</div>
                  <p>{RichText.render(item.description)}</p>
                </div>
                <div className="first-section__card__footer" onClick={showModal}>
                  <span>{RichText.asText(item.button)}</span>
                  <img src={iconArrowRight} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SecondSection = ({ prismicData: { secondSectionTitle, secondSectionFaq } }) => (
  <section className="second-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-xl-9 mx-auto">
          <div className="c-h1">{RichText.asText(secondSectionTitle)}</div>
          {secondSectionFaq.map((item, index) => (
            <PanelFaq title={RichText.asText(item.title)} key={index}>
              {RichText.render(item.description)}
            </PanelFaq>
          ))}
        </div>
      </div>
    </div>
  </section>
);

const ThirdSection = ({ prismicData: { thirdSectionTitle, thirdSectionSubtitle } }) => (
  <section className="third-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-xl-9 mx-auto">
          <div className="third-section__card">
            <img src={imageCustomers} alt="customers" />
            <div className="c-h5--bold">{RichText.asText(thirdSectionTitle)}</div>
            <p>{RichText.asText(thirdSectionSubtitle)}</p>
            <Link to="/pricing/" className="c-btn">
              Get Started
            </Link>
            <img className="third-section__confeti--left" src={imageConfetiLeft} alt="" />
            <img className="third-section__confeti--right" src={imageConfetiRight} alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PricingPage;

export const query = graphql`
  query {
    prismic {
      allPricing_pages {
        edges {
          node {
            firstSectionTitle
            firstSectionCaption
            firstSectionCards {
              title
              description
              button
              image
            }
            secondSectionTitle
            secondSectionFaq {
              title
              description
            }
            thirdSectionTitle
            thirdSectionSubtitle
          }
        }
      }
    }
  }
`;
